import { render, staticRenderFns } from "./bricklink.vue?vue&type=template&id=9bdefc8a&scoped=true"
import script from "./bricklink.vue?vue&type=script&lang=js"
export * from "./bricklink.vue?vue&type=script&lang=js"
import style0 from "./bricklink.vue?vue&type=style&index=0&id=9bdefc8a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bdefc8a",
  null
  
)

export default component.exports
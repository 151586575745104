/*
 * @Description:element 按需引入
 * @Version: 2.0
 * @Autor: LYH
 * @Date: 2023-12-19 14:12:24
 * @LastEditors: LYH
 * @LastEditTime: 2023-12-19 14:13:29
 */

import Vue from 'vue'
import {
    Pagination,
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    TimePicker,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Tree,
    Alert,
    Slider,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Spinner,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Cascader,
    ColorPicker,
    Transfer,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Timeline,
    TimelineItem,
    Link,
    Divider,
    Image,
    Calendar,
    Backtop,
    PageHeader,
    CascaderPanel,
    Loading,
    MessageBox,
    Message,
    Notification
} from 'element-ui'
import VueLazyload from 'vue-lazyload'

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Alert)
Vue.use(Slider)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Spinner)
Vue.use(Badge)
Vue.use(Card)
Vue.use(Rate)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Cascader)
Vue.use(ColorPicker)
Vue.use(Transfer)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Link)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Calendar)
Vue.use(Backtop)
Vue.use(PageHeader)
Vue.use(CascaderPanel)
Vue.use(VueLazyload, {
    error: require('@/assets/img/part/question.png'),
    attempt: 1
})

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
Vue.prototype.$constant = {
    PART_STATUS_AVAILABLE: 0,
    PART_STATUS_NOPART: 1,
    PART_STATUS_NOCOLOR: 2,
    PART_STATUS_NOSTOCK: 3,
    PART_STATUS_UPDATE: 4,

    MODIFY_DELETE: 1,
    MODIFY_ADD: 2,
    MODIFY_UPDATE: 3,

    RECOMMENDTYPE_MOLD: 1,
    RECOMMENDTYPE_PRINTOF: 2,
    RECOMMENDTYPE_RELATECOLOR: 4,
    RECOMMENDTYPE_OTHER: 8,
    RECOMMENDTYPE_DELETE: 999,

    TYPE_PART: 1,
    TYPE_CATEGORY: 2,

    TRANSPORT_FEE: 10,
    TRANSPORT_FEE_FREE: 49,
    PACKAGE_MAXCOUNT: 5,
    SET_MAXCOUNT: 100
}
Vue.prototype.$global = {
    sellers: {}
}

// 全局方法
import { instancePhp } from '@/http'
// 在Vue实例化之前，初始化$timeOffset
Vue.prototype.$initSign = async function (method) {
    try {
        let servertime_offset = this.$store.state.servertime_offset
        if (!servertime_offset) servertime_offset = 0
        let requestTime = new Date().getTime() + servertime_offset
        let response = await instancePhp.get('index.php?m=init&t=' + requestTime + '&method=' + method)
        // 检查返回的code是否为-2
        if (response.data.code == -2) {
            const serverTime = response.data.time // 服务器时间
            console.debug('init sign fail, recalculate servertimeoffset', requestTime, response.data.time, servertime_offset, serverTime - (requestTime - servertime_offset))
            servertime_offset = serverTime - (requestTime - servertime_offset)
            this.$store.commit('servertime_offset', servertime_offset)
            // 使用计算出的时间偏移再次发起请求
            response = await instancePhp.get('index.php?m=init&t=' + (new Date().getTime() + servertime_offset) + '&method=' + method)
        }
        // 再次检查，如果还有错误code != 0，则弹窗爆出异常
        if (response.data.code != 0) {
            this.$alert('服务器异常，请联系管理员客服，稍后尝试', '错误', {
                confirmButtonText: '确定',
                callback: () => {
                    console.log('服务器异常处理')
                }
            })
            return
        }
        this.$store.commit('sign', response.data)
        return response.data
    } catch (error) {
        console.error(error)
    }
}
Vue.prototype.$convertor = {
    $convertPackages: (filename, resp) => {
        let parts = {
            parts: [],
            packages: []
        }
        parts.parts = resp.parts.flatMap((part) => Vue.prototype.$convertor.$convertParts(part)) // 使用$convertParts替代__$convertPart

        const groupedParts = parts.parts.reduce((acc, part) => {
            if (!acc[part.sourceid]) {
                acc[part.sourceid] = []
            }
            acc[part.sourceid].push(part)
            return acc
        }, {})

        parts.packages = Object.entries(groupedParts).map(([sourceid, sourceParts], index) => ({
            id: index,
            sourceid: parseInt(sourceid),
            seller: resp.sellers.find((s) => s.sid === parseInt(sourceid)) || {},
            packagename: filename,
            parts: {
                availables: sourceParts.filter((i) => i.stockavailable),
                pendings: sourceParts.filter((i) => !i.stockavailable)
            }
        }))

        parts.packages.forEach(({ seller, sourceid }) => {
            Vue.prototype.$global.sellers[sourceid] = seller
        })

        return parts
    },
    $convertParts: (partDto) => {
        if (partDto.relateparts && partDto.relateparts.length > 0) {
            return partDto.relateparts.map((part) => Vue.prototype.$convertor.__$convertPart(part))
        } else {
            return [Vue.prototype.$convertor.__$convertPart(partDto)]
        }
    },
    __$convertPart: (dto) => {
        let status = Vue.prototype.$constant.PART_STATUS_AVAILABLE
        if (!dto.partavailable) status = Vue.prototype.$constant.PART_STATUS_NOPART
        else if (!dto.salescoloravailable) status = Vue.prototype.$constant.PART_STATUS_NOCOLOR
        else if (!dto.salesstockavailable) status = Vue.prototype.$constant.PART_STATUS_NOSTOCK
        let selectable = true
        if (status === Vue.prototype.$constant.PART_STATUS_AVAILABLE || status === Vue.prototype.$constant.PART_STATUS_UPDATE) selectable = false

        let part = {
            selectable: selectable,
            type: Vue.prototype.$constant.TYPE_PART,
            id: dto.id,
            name: dto.salesskucode ? dto.salesskucode : dto.bldesigncode + ' - ' + dto.blcolorid,
            subname: dto.salesname ? dto.salesname : dto.partname,
            icon: dto.salesicon ? dto.salesicon : dto.particon,
            price: parseFloat(dto.salesprice) || 0,
            num: dto.qty,
            total: parseFloat(dto.salestotalprice) || 0,
            status: status,
            sourceid: dto.salessourceid,

            //内部属性
            itemid: dto.salesskucode,
            ename: dto.partname,
            designcode: dto.partcode,
            colorid: dto.blcolorid ? dto.blcolorid.toString() : '',
            mrpdesigncode: dto.bldesigncode,
            mrpcolorid: dto.blcolorid ? dto.blcolorid.toString() : '',
            itemcolorid: dto.salescolorcode,
            colorname: dto.partchcolorname,
            ecolorname: dto.partcolorname,
            stockqty: dto.salesstockqty,
            stockavailable: dto.salesstockavailable,
            available: dto.partavailable,
            coloravailable: dto.salescoloravailable,
            colorhtml: dto.partcolorhtml,
            colorRGB: dto.partcolorhtml ? Vue.prototype.$convertor.__$convertColorRGB(dto.partcolorhtml) : undefined,
            recommendtype: dto.recommendtype,
            recommendparts: dto.recommendparts?.map((part) => Vue.prototype.$convertor.__$convertPart(part)),
            parts: dto.parts?.filter((part) => part.salesstockavailable).map((part) => Vue.prototype.$convertor.__$convertPart(part))
        }

        if (part.available && !part.coloravailable && (!dto.parts || dto.parts.length <= 0)) {
            part.available = false
            part.coloravailable = false
            part.stockavailable = false
        }

        return part
    },
    // __$convertPart: (part) => {
    //     let status = Vue.prototype.$constant.PART_STATUS_AVAILABLE
    //     if (!part.available) status = Vue.prototype.$constant.PART_STATUS_NOPART
    //     else if (!part.coloravailable) status = Vue.prototype.$constant.PART_STATUS_NOCOLOR
    //     else if (!part.stockavailable) status = Vue.prototype.$constant.PART_STATUS_NOSTOCK
    //     let selectable = true
    //     if (status === Vue.prototype.$constant.PART_STATUS_AVAILABLE || status === Vue.prototype.$constant.PART_STATUS_UPDATE) selectable = false
    //     return {
    //         selectable: selectable,
    //         type: Vue.prototype.$constant.TYPE_PART,
    //         id: part.id,
    //         name: part.itemid ? part.itemid : part.mrpdesigncode + ' - ' + part.mrpcolorid,
    //         subname: part.name ? part.name : part.partname,
    //         icon: part.icon,
    //         price: part.price,
    //         num: part.qty,
    //         total: part.totalprice,
    //         status: status,
    //         sourceid: part.sourceid,

    //         //内部属性
    //         itemid: part.itemid,
    //         ename: part.partname,
    //         designcode: part.designcode,
    //         colorid: part.colorid,
    //         mrpdesigncode: part.mrpdesigncode,
    //         mrpcolorid: part.mrpcolorid,
    //         itemcolorid: part.itemcolorid,
    //         colorname: part.colorname,
    //         ecolorname: part.ecolorname,
    //         stockqty: part.stockqty,
    //         stockavailable: part.stockavailable,
    //         available: part.available,
    //         coloravailable: part.coloravailable,
    //         colorhtml: part.colorhtml,
    //         colorRGB: part.colorhtml ? Vue.prototype.$convertor.__$convertColorRGB(part.colorhtml) : undefined,
    //         recommendtype: part.recommendtype,
    //         recommendparts: part.recommendparts?.map((part) => Vue.prototype.$convertor.__$convertPart(part)),
    //         parts: part.parts?.map((part) => Vue.prototype.$convertor.__$convertPart(part))
    //     }
    // },
    __$convertColorRGB: (colorhtml) => {
        // 转为RGB数组
        let color = colorhtml
        let r = parseInt(color.slice(0, 2), 16)
        let g = parseInt(color.slice(2, 4), 16)
        let b = parseInt(color.slice(4, 6), 16)
        return [r, g, b]
    }
}

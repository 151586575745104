<template>
    <div class="flex-col group_12">
        <div v-if="items.length === 0" class="loading-container">加载中...</div>
        <div v-else class="flex-row self-stretch group_15">
            <div v-for="(items, columnIndex) in items_col" :key="columnIndex" class="flex-col list ml-12">
                <div class="flex-col relative section_8 list-item_2 mt-11" v-for="(item, index) in items" :key="index">
                    <div class="flex-row items-center self-stretch group_19">
                        <img class="image_10" src="@/assets/img/moc/head.gif" />
                        <div class="flex-col items-start group_9 ml-13">
                            <span class="font_7" @click="handleUserTab(item.author)">{{ item.author }}</span>
                            <span class="font_10 mt-7">{{ item.publishtimeT }}</span>
                        </div>
                    </div>
                    <div class="shrink-0 self-start section_17">
                        <img class="shrink-0 image_12 pos_4" :src="`https://website-10023938.cos.ap-shanghai.myqcloud.com/${item.imageurl}`" @click="navigateToUrl(item)" />
                    </div>
                    <div class="flex-row group_27 pos_8">
                        <span class="self-start font_7 text_45">{{ item.modelname }}</span>
                        <div class="flex-col shrink-0 relative group_21 ml-97">
                            <div class="flex-row items-center self-stretch section_15 mt-312">
                                <span class="font_4 text_46 ml-7" @click="showImageModal(item)">查看图纸</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { instance } from '@/http'
export default {
    data() {
        return {
            userid: null,

            pageindex: 0,
            isfinish: false,
            isscrolling: false,
            items: [],
            items_col: []
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search)
        this.userid = urlParams.get('userid') || ''
        console.log(this.userid)
        this.apiRebrickableList(this.userid)
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('popstate', this.handlePopState)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('popstate', this.handlePopState)
    },
    methods: {
        apiRebrickableList: async function (userid) {
            if (this.isfinish) return
            const { t, sign } = await this.$initSign('rebrickable.list')
            const response = await instance.get('/console/rebrickable/list', {
                params: { t, sign, author: userid, pageindex: this.pageindex }
            })
            this.isfinish = response.data.data.length < 20
            const formatDate = (timestamp) => {
                const date = new Date(timestamp) // Convert to milliseconds
                const year = date.getFullYear()
                const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
                const day = String(date.getDate()).padStart(2, '0')
                return `${year}-${month}-${day}`
            }

            response.data.data.forEach((i) => {
                i.staffpicktimeT = formatDate(i.staffpicktime)
                i.publishtimeT = formatDate(i.publishtime)
            })
            this.items = [...this.items, ...response.data.data]

            const tempItems0 = []
            const tempItems1 = []
            const tempItems2 = []

            // Distribute items into three columns
            this.items.forEach((item, index) => {
                if (index % 3 === 0) {
                    tempItems0.push(item)
                } else if (index % 3 === 1) {
                    tempItems1.push(item)
                } else {
                    tempItems2.push(item)
                }
            })

            // Assign the temporary arrays to the data properties
            this.items_col = [tempItems0, tempItems1, tempItems2]
            this.isscrolling = false
        },
        handleScroll: async function () {
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight - 900
            if (bottomOfWindow && !this.isscrolling) {
                console.log('scrolling ', this.pageindex)
                if (this.isfinish) return
                this.pageindex++
                this.isscrolling = true
                await this.apiRebrickableList(this.userid)
                this.isscrolling = false
            }
        },
        handleUserTab(userid) {
            this.isscrolling = true
            window.scrollTo(0, 0)

            this.userid = userid
            this.items = []
            this.items_col = []
            this.pageindex = 0
            this.isfinish = false
            this.apiRebrickableList(userid)

            const url = new URL(window.location.href)
            const hash = url.hash.split('?')[0]
            url.hash = `${hash}?userid=${userid}`
            window.history.pushState({}, '', url)
        },
        navigateToUrl(item) {
            const url = `https://rebrickable.com/mocs/MOC-${item.modelid}/`
            window.open(url, '_blank')
        },
        async showImageModal(item) {
            const { t, sign } = await this.$initSign('infowxacode')
            const url = `${instance.defaults.baseURL}/console/bricklink/infowxacode?t=${t}&sign=${sign}&sourceid=2&moccode=${item.modelid}`
            this.$alert(`<div style="text-align: center;"><img src="${url}" alt="Model Image" style="max-width: 100%;"></div>`, '查看图纸', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '关闭',
                closeOnClickModal: true
            }).catch(() => {
                // 处理取消的情况
                console.log('弹出框被取消')
            })
        },
        handlePopState() {
            this.isscrolling = true

            const urlParams = new URLSearchParams(window.location.search)
            this.userid = urlParams.get('userid') || ''
            this.items = []
            this.items_col = []
            this.pageindex = 0
            this.isfinish = false
            this.apiRebrickableList(this.userid)
        }
    }
}
</script>

<style scoped lang="scss">
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-evenly {
    justify-content: space-evenly;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-stretch {
    align-self: stretch;
}

.shrink-0 {
    flex-shrink: 0;
}

.relative {
    position: relative;
}

.ml-12 {
    margin-left: 12px;
}

.mt-7 {
    margin-top: 7px;
}

.ml-97 {
    margin-left: 17px;
}

.mt-312 {
    margin-top: 345px;
}

.ml-13 {
    margin-left: 13px;
}

.mt-11 {
    margin-top: 11px;
}

.page {
    font-family: PingFang SC;
    background: url('@/assets/img/matching/bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 16px 0;

    .group_7 {
        .section {
            padding-bottom: 33px;

            .section_12 {
                padding: 2px 12px 203px;
                background-color: #ffffff;
                width: 1072px;

                .section_10 {
                    background-color: #ffffff;

                    .text-wrapper_5 {
                        padding: 9.5px 0 7px;
                        background-color: #ffffff;
                        border-radius: 4px 0px 0px 4px;
                        width: 1021px;
                        height: 32px;
                        border-left: solid 1px #d8dbde;
                        border-top: solid 1px #d8dbde;
                        border-bottom: solid 1px #d8dbde;

                        .text_20 {
                            margin-left: 13.5px;
                            color: #757575;
                            line-height: 13.5px;
                        }
                    }

                    .image_8 {
                        border-radius: 0px 4px 4px 0px;
                        width: 27px;
                        height: 32px;
                    }
                }

                .group_10 {
                    margin-top: 24px;
                    border-bottom: solid 1px #d8dbde;

                    .group_11 {
                        width: 115.5px;
                        height: 35px;
                        border-bottom: solid 4px #0788ca;

                        .section_14 {
                            margin-top: -10px;
                            margin-left: 5px;
                            padding: 13.5px 0 16px;
                            background-color: #d7ecf7;
                            border-radius: 4px;

                            .image_9 {
                                width: 16px;
                                height: 14px;
                            }

                            .text_21 {
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 10.5px;
                                cursor: pointer;
                            }
                        }
                    }

                    .font_5 {
                        font-size: 14px;
                        font-family: Roboto;
                        line-height: 10.5px;
                        color: #666666;
                    }

                    .text_22 {
                        margin-top: 5px;
                        line-height: 10px;
                        cursor: pointer;
                    }
                }

                .group_12 {
                    padding-top: 16px;
                    overflow: hidden;

                    .group_15 {
                        padding-top: 23px;

                        .list {
                            width: 341.5px;

                            .section_8 {
                                background-color: #ffffff;
                                border-radius: 4px;
                                overflow: hidden;
                                border: solid 1px #d8dbde;

                                .section_17 {
                                    background-color: #eeeeee;
                                    width: 100%;
                                    position: relative;

                                    .image_12 {
                                        background-color: #eeeeee;
                                        width: 100%;
                                        height: auto;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        cursor: pointer;
                                    }
                                }

                                .group_27 {
                                    padding: 0 12px;
                                    height: 53px;
                                    border-top: solid 1px #d8dbde;
                                    display: flex;
                                    justify-content: space-between;

                                    .text_45 {
                                        margin-top: 21px;
                                        width: 200px;
                                        line-height: 13.5px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .group_21 {
                                        margin-top: -332.5px;
                                        .section_15 {
                                            padding: 6px;
                                            background-color: #ffffff;
                                            border-radius: 4px;
                                            border: solid 1px #d8dbde;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            cursor: pointer;
                                            &:hover {
                                                background-color: #d7ecf7;
                                            }

                                            .text_46 {
                                                padding: 3px;
                                                font-size: 13px;
                                                line-height: 10px;
                                            }
                                        }
                                    }
                                }

                                .pos_8 {
                                    position: relative;
                                    left: 0;
                                    right: 0;
                                }
                            }

                            .list-item_2 {
                                &:first-child {
                                    margin-top: 0;
                                }
                            }

                            .group_19 {
                                padding: 12px;
                                border-bottom: solid 1px #d8dbde;

                                .image_10 {
                                    border-radius: 20px;
                                    width: 40px;
                                    height: 40px;
                                }

                                .group_9 {
                                    width: 263px;
                                }
                            }

                            .image-wrapper {
                                padding: 8.5px 0;
                                background-color: #ffffff;
                                border-radius: 16px;
                                width: 32px;
                            }

                            .image_14 {
                                width: 16px;
                                height: 15px;
                            }
                        }
                    }

                    .font_10 {
                        font-size: 12px;
                        font-family: Roboto;
                        line-height: 9px;
                        color: #666666;
                    }
                }

                .font_7 {
                    font-size: 14px;
                    font-family: Roboto;
                    line-height: 13.5px;
                    color: #111111;
                    cursor: pointer;
                    user-select: none;
                    &:active {
                        color: #0788ca;
                    }
                }

                .font_4 {
                    font-size: 14px;
                    font-family: Roboto;
                    line-height: 10.5px;
                    color: #111111;
                }
            }
        }
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // Adjust as needed
    font-size: 16px; // Adjust font size as needed
    color: #666; // Adjust color as needed
}
</style>
